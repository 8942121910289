import { gql } from "@apollo/client";
export const LOGINADMIN = gql`
  query getAdminUserByEmail($email: String) {
    admin_user(where: { email: { _eq: $email } }) {
      id

      name

      email

      phone_number

      password
    }
  }
`;

export const USER_STAKES_REPORT = gql`
  query getStakeSummary {
    view_staked_summary {
      staked_by
      total_paid_amount
      total_records
      total_staked_amount
    }
  }
`;

export const GET_ALL_DEPARTMENTS = gql`
  query getAllActiveDepartments {
    departments: department {
      id

      name

      is_active
    }
  }
`;

export const GET_ALL_ADMINS = gql`
  query getAllAdmins {
    admin(order_by: { status: desc }) {
      auth_user_id
      created_at
      department_id
      email
      first_name
      id
      last_name
      phone_number
      middle_name
      status
    }
  }
`;

export const GET_SMS_LOG = gql`
  query getSMSLog {
    sms_log {
      id

      title

      status

      sent_date

      schedule_date

      message

      sender {
        id

        name
      }

      recipient {
        id

        customers {
          full_name
        }

        intermediary_user {
          name
        }
      }
    }
  }
`;

// export const GET_ALL_USERS = gql`
//   query getUsers {
//     user(order_by: { created_at: desc }) {
//       id

//       is_verified

//       email

//       intermediary_user {
//         full_name: name

//         phone_number

//         intermediary {
//           name
//         }
//       }

//       customer {
//         full_name

//         phone_number

//         company_name
//       }
//     }
//   }
// `;

export const USERS_SUMMARY = gql`
  query getUsersCount {
    admins: admin_aggregate {
      aggregate {
        count
      }
    }
    agents: customer_aggregate(where: { user_type: { _eq: "agent" } }) {
      aggregate {
        count
      }
    }
    regular_customers: customer_aggregate(
      where: { user_type: { _eq: "customer" } }
    ) {
      aggregate {
        count
      }
    }
    writers: writer_aggregate {
      aggregate {
        count
      }
    }
    writer_fms: writer_fms_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const GET_USER_DETAILS = gql`
  query getCustomersDetails {
    customer {
      full_name
      phone_number
    }
  }
`;

export const GET_ALL_USERS = gql`
  query getallusers {
    admin {
      id
      last_name
      first_name
      middle_name
      phone_number
    }
    customer {
      first_name
      full_name
      phone_number
      user_type
      id
    }
  }
`;

export const GET_USER_BETWEEN_RANGE = gql`
  query MyQuery(
    $_adminAfter: timestamptz
    $_adminBefore: timestamptz
    $_customerAfter: timestamptz
    $_customerBefore: timestamptz
  ) {
    admin(where: { created_at: { _gte: $_adminAfter, _lte: $_adminBefore } }) {
      first_name
      last_name
      phone_number
      status
      email
    }
    customer(
      where: { created_at: { _gte: $_customerAfter, _lte: $_customerBefore } }
    ) {
      first_name
      last_name
      phone_number
      status
      email
    }
  }
`;

export const GET_PAYMENTS_WITHIN_RANGE = gql`
  query getAllPaymentsWithinRange(
    $_timeAfter: timestamptz
    $_timeBefore: timestamptz
  ) {
    payment(where: { created_at: { _gte: $_timeAfter, _lte: $_timeBefore } }) {
      __typename
      id
      game {
        product {
          name
        }
      }
      paid_at
      reference
      status
      third_party_ref
      total_amount
    }
  }
`;

export const GET_ALL_PRODUCTS_SPONZIO = gql`
  query Products {
    mondays: product(
      order_by: { name: asc }
      where: { is_active: { _eq: true }, schedule_day: { _contains: "mon" } }
    ) {
      id
      name
      image
      run_end
      run_start
      schedule_day
      schedule_end
      schedule_start
      is_active
      games(
        limit: 1
        order_by: { schedule_start: asc }
        where: {
          is_active: { _eq: true }
          schedule_start: { _gte: now }
          schedule_end: { _gte: now }
        }
      ) {
        id
        event_code
        results
        properties
        run_start
        run_end
        schedule_start
        schedule_end
        odds
      }
      owner {
        id
        name
        logo
      }
      properties
      product_type: productTypeByProductType {
        id
        category
        is_active
      }
      max_stake_amount
      min_stake_amount
      prefix
      slogan
      schedule_type
      claim_expiry_hours

      description
    }
    tuesday: product(
      order_by: { name: asc }
      where: { is_active: { _eq: true }, schedule_day: { _contains: "tue" } }
    ) {
      id
      name
      image
      run_end
      run_start
      schedule_day
      schedule_end
      schedule_start
      is_active
      games(
        limit: 1
        order_by: { schedule_start: asc }
        where: {
          is_active: { _eq: true }
          schedule_start: { _gte: now }
          schedule_end: { _gte: now }
        }
      ) {
        id
        event_code
        results
        properties
        run_start
        run_end
        schedule_start
        schedule_end
        odds
      }
      owner {
        id
        name
        logo
      }
      properties
      product_type: productTypeByProductType {
        id
        category
        is_active
      }
      max_stake_amount
      min_stake_amount
      prefix
      slogan
      schedule_type
      claim_expiry_hours

      description
    }
    wednesday: product(
      order_by: { name: asc }
      where: { is_active: { _eq: true }, schedule_day: { _contains: "wed" } }
    ) {
      id
      name
      image
      run_end
      run_start
      schedule_day
      schedule_end
      schedule_start
      is_active
      games(
        limit: 1
        order_by: { schedule_start: asc }
        where: {
          is_active: { _eq: true }
          schedule_start: { _gte: now }
          schedule_end: { _gte: now }
        }
      ) {
        id
        event_code
        results
        properties
        run_start
        run_end
        schedule_start
        schedule_end
        odds
      }
      owner {
        id
        name
        logo
      }
      properties
      product_type: productTypeByProductType {
        id
        category
        is_active
      }
      max_stake_amount
      min_stake_amount
      prefix
      slogan
      schedule_type
      claim_expiry_hours

      description
    }
    thursday: product(
      order_by: { name: asc }
      where: { is_active: { _eq: true }, schedule_day: { _contains: "thu" } }
    ) {
      id
      name
      image
      run_end
      run_start
      schedule_day
      schedule_end
      schedule_start
      is_active
      games(
        limit: 1
        order_by: { schedule_start: asc }
        where: {
          is_active: { _eq: true }
          schedule_start: { _gte: now }
          schedule_end: { _gte: now }
        }
      ) {
        id
        event_code
        results
        properties
        run_start
        run_end
        schedule_start
        schedule_end
        odds
      }
      owner {
        id
        name
        logo
      }
      properties
      product_type: productTypeByProductType {
        id
        category
        is_active
      }
      max_stake_amount
      min_stake_amount
      prefix
      slogan
      schedule_type
      claim_expiry_hours

      description
    }
    friday: product(
      order_by: { name: asc }
      where: { is_active: { _eq: true }, schedule_day: { _contains: "fri" } }
    ) {
      id
      name
      image
      run_end
      run_start
      schedule_day
      schedule_end
      schedule_start
      is_active
      games(
        limit: 1
        order_by: { schedule_start: asc }
        where: {
          is_active: { _eq: true }
          schedule_start: { _gte: now }
          schedule_end: { _gte: now }
        }
      ) {
        id
        event_code
        results
        properties
        run_start
        run_end
        schedule_start
        schedule_end
        odds
      }
      owner {
        id
        name
        logo
      }
      properties
      product_type: productTypeByProductType {
        id
        category
        is_active
      }
      max_stake_amount
      min_stake_amount
      prefix
      slogan
      schedule_type
      claim_expiry_hours

      description
    }
    saturday: product(
      order_by: { name: asc }
      where: { is_active: { _eq: true }, schedule_day: { _contains: "sat" } }
    ) {
      id
      name
      image
      run_end
      run_start
      schedule_day
      schedule_end
      schedule_start
      is_active
      games(
        limit: 1
        order_by: { schedule_start: asc }
        where: {
          is_active: { _eq: true }
          schedule_start: { _gte: now }
          schedule_end: { _gte: now }
        }
      ) {
        id
        event_code
        results
        properties
        run_start
        run_end
        schedule_start
        schedule_end
        odds
      }
      owner {
        id
        name
        logo
      }
      properties
      product_type: productTypeByProductType {
        id
        category
        is_active
      }
      max_stake_amount
      min_stake_amount
      prefix
      slogan
      schedule_type
      claim_expiry_hours

      description
    }
    sunday: product(
      order_by: { name: asc }
      where: { is_active: { _eq: true }, schedule_day: { _contains: "sun" } }
    ) {
      id
      name
      image
      run_end
      run_start
      schedule_day
      schedule_end
      schedule_start
      is_active
      games(
        limit: 1
        order_by: { schedule_start: asc }
        where: {
          is_active: { _eq: true }
          schedule_start: { _gte: now }
          schedule_end: { _gte: now }
        }
      ) {
        id
        event_code
        results
        properties
        run_start
        run_end
        schedule_start
        schedule_end
        odds
      }
      owner {
        id
        name
        logo
      }
      properties
      product_type: productTypeByProductType {
        id
        category
        is_active
      }
      max_stake_amount
      min_stake_amount
      prefix
      slogan
      schedule_type
      claim_expiry_hours
      description
    }
  }
`;

export const GET_ALL_PRODUCTS_TYPES = gql`
  query GetProductType {
    product_type {
      category
      created_at
      id
      is_active
      name
    }
  }
`;

export const GET_ALL_PRODUCT_OWNERS = gql`
  query GetProductOwner {
    product_owner {
      created_at
      email
      id
      is_active
      name
      phone
      postal_address
    }
  }
`;

export const GET_TOTAL_AGENTS = gql`
  query TotalAgent($customer_type: String) {
    customer_aggregate(where: { user_type: { _eq: $customer_type } }) {
      aggregate {
        count
      }
    }
  }
`;
export const GET_TOTAL_CUSTOMERS = gql`
  query TotalCustomers($customer_type: String) {
    customer_aggregate(where: { user_type: { _eq: $customer_type } }) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_ALL_WINNERS_PAYOUT = gql`
  query WinnersPayout {
    winning_claim_aggregate(where: { status: { _eq: "paid" } }) {
      aggregate {
        sum {
          amount
        }
      }
    }
  }
`;

export const GET_ALL_AGENT_COMMISSION = gql`
  query GetAllAgentCommisions {
    agent_commission_payment_aggregate {
      aggregate {
        sum {
          amount
        }
      }
    }
  }
`;

export const GET_TODAY_WINNING_NUMBERS = gql`
  query GetResultsForDay($gameDay: date) {
    game(
      limit: 1
      where: { schedule_end: { _eq: $gameDay } }
      order_by: { closed_at: desc }
    ) {
      results
    }
  }
`;
export const GET_GAME_OF_DAY = gql`
  query gameOfTheDay($schedule_day: jsonb) {
    game(
      limit: 1
      order_by: [{ schedule_start: asc }, { run_end: asc }]
      where: {
        game_of_day: { _eq: true }
        is_active: { _eq: true }
        schedule_start: { _lte: now }
        schedule_end: { _gte: now }
        product: { schedule_day: { _contains: $schedule_day } }
      }
    ) {
      id
      results
      schedule_start
      schedule_end
      run_start
      run_end
      event_code
      claim_expiry_hours
      is_active
      product {
        id
        name
        image
        description
        slogan
        min_stake_amount
        max_stake_amount
        schedule_start
        schedule_end
        schedule_day
        schedule_type
        is_active
        properties
      }
    }
  }
`;

export const GET_TODAY_GAMES = gql`
  query GetTodayGames($gameDay: date) {
    game(where: { schedule_start: { _eq: $gameDay } }, order_by: { id: desc }) {
      results
      claim_expiry_hours
      closed_at
      event_code
      id
      product {
        description
        id
        image
        max_stake_amount
        min_stake_amount
        name
        slogan
        schedule_day
      }
      run_end
      run_start
      schedule_end
      schedule_start
      game_of_day
      is_active
    }
  }
`;

export const GET_REGIONAL_BALANCE = gql`
  query GetRegionalBalance {
    view_regional_agent_commissions {
      is_commission_paid
      name
      total_commissions
      region_id
    }
  }
`;

export const GETLASTGAME = gql`
  query getLastGame {
    game(
      where: { is_active: { _eq: false } }
      order_by: { closed_at: desc, created_at: asc }
      limit: 1
    ) {
      product {
        id
        name
      }
      results
      id
    }
  }
`;

export const GET_GAME_OF_DAY_NEW = gql`
  query gameOfTheDay($schedule_day: jsonb) {
    game(
      limit: 1
      order_by: [{ created_at: desc }]
      where: {
        is_active: { _eq: true }
        schedule_end: { _gte: now }
        schedule_start: { _lte: now }
        product: { schedule_day: { _contains: $schedule_day } }
      }
    ) {
      id
      results
      schedule_start
      schedule_end
      run_start
      run_end
      event_code
      claim_expiry_hours
      is_active
      closed_at
      product {
        id
        name
        image
        description
        slogan
        min_stake_amount
        max_stake_amount
        schedule_start
        schedule_end
        schedule_day
        schedule_type
        is_active
        properties
      }
      game_of_day
    }
  }
`;

export const GET_EVENTS_OF_PRODUCT = gql`
  query GetAllEventsOfProduct($productID: uuid!) {
    game(where: { product_id: { _eq: $productID } }) {
      claim_expiry_hours
      closed_at
      created_by
      event_code
      game_of_day
      id
      is_active
      product_id
      run_end
      run_start
      results
      schedule_end
      schedule_start
      product {
        description
        max_stake_amount
        min_stake_amount
        name
        slogan
        schedule_day
      }
    }
  }
`;

export const GET_TODAY_WINNERS = gql`
  query winnerForToday($date: date) {
    stake_aggregate(
      order_by: { game: { created_at: desc } }
      where: {
        stake_status: { _eq: "won" }
        payment: { game: { schedule_end: { _eq: $date } } }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const TODAY_GAME = gql`
  query TodayGame($gameDate: date) {
    game(where: { schedule_start: { _eq: $gameDate } }) {
      product_id
      product {
        name
      }
      event_code
      id
    }
  }
`;

export const GET_ALL_AGENT_COMMISSION_PRICING = gql`
  query getAllAgentCommissionPricing {
    agent_commission_pricing {
      id
      created_at
      max_stake_amount
      min_stake_amount
      compute_as
      rate
      updated_at
    }
  }
`;

export const GET_ALL_AGENTS = gql`
  query getAgents {
    customer(
      where: { user_type: { _eq: "agent" } }
      order_by: { created_at: desc }
    ) {
      first_name
      last_name
      email
      user_type
      phone_number
      status
      is_active
      created_at
      id
      digital_address
      ghana_card
      middle_name
      national_id
      town_city
      region_id
      selfie
      blocked
      blocked_reason
      address
      auth_user_id
    }
  }
`;

export const LOGINUSER = gql`
  query Login($user_id: String) {
    admin(where: { auth_user_id: { _eq: $user_id } }) {
      id
      first_name
      email
      middle_name
      last_name
      phone_number
      status
      role {
        code
        name
        id
      }
    }
  }
`;

export const GET_TOTAL_STAKES_REPORT = gql`
  query getTotalStakesReport(
    $_startDate: timestamptz
    $_endDate: timestamptz
    $_productId: uuid!
  ) {
    paid_stakes: stake_aggregate(
      where: {
        game: { product_id: { _eq: $_productId } }
        created_at: { _gte: $_startDate, _lte: $_endDate }
        is_paid: { _eq: true }
      }
    ) {
      aggregate {
        count
        sum {
          staked_amount
        }
      }
    }
    attempted_stakes: stake_aggregate(
      where: {
        game: { product_id: { _eq: $_productId } }
        created_at: { _gte: $_startDate, _lte: $_endDate }
        is_paid: { _eq: false }
      }
    ) {
      aggregate {
        count
        sum {
          staked_amount
        }
      }
    }
  }
`;

export const MONTHLYWINNING_GRAPH_DATA = gql`
  query getMonthlyWininings($year: String) {
    january: view_month_win_amounts_aggregate(
      where: { _month: { _eq: "january" }, _year: { _eq: $year } }
    ) {
      aggregate {
        sum {
          total_won
        }
      }
    }
    february: view_month_win_amounts_aggregate(
      where: { _month: { _eq: "february" }, _year: { _eq: $year } }
    ) {
      aggregate {
        sum {
          total_won
        }
      }
    }
    march: view_month_win_amounts_aggregate(
      where: { _month: { _eq: "march" }, _year: { _eq: $year } }
    ) {
      aggregate {
        sum {
          total_won
        }
      }
    }
    april: view_month_win_amounts_aggregate(
      where: { _month: { _eq: "april" }, _year: { _eq: $year } }
    ) {
      aggregate {
        sum {
          total_won
        }
      }
    }
    may: view_month_win_amounts_aggregate(
      where: { _month: { _eq: "may" }, _year: { _eq: $year } }
    ) {
      aggregate {
        sum {
          total_won
        }
      }
    }
    june: view_month_win_amounts_aggregate(
      where: { _month: { _eq: "june" }, _year: { _eq: $year } }
    ) {
      aggregate {
        sum {
          total_won
        }
      }
    }
    july: view_month_win_amounts_aggregate(
      where: { _month: { _eq: "july" }, _year: { _eq: $year } }
    ) {
      aggregate {
        sum {
          total_won
        }
      }
    }
    august: view_month_win_amounts_aggregate(
      where: { _month: { _eq: "august" }, _year: { _eq: $year } }
    ) {
      aggregate {
        sum {
          total_won
        }
      }
    }
    september: view_month_win_amounts_aggregate(
      where: { _month: { _eq: "september" }, _year: { _eq: $year } }
    ) {
      aggregate {
        sum {
          total_won
        }
      }
    }
    october: view_month_win_amounts_aggregate(
      where: { _month: { _eq: "october" }, _year: { _eq: $year } }
    ) {
      aggregate {
        sum {
          total_won
        }
      }
    }
    november: view_month_win_amounts_aggregate(
      where: { _month: { _eq: "november" }, _year: { _eq: $year } }
    ) {
      aggregate {
        sum {
          total_won
        }
      }
    }
    december: view_month_win_amounts_aggregate(
      where: { _month: { _eq: "december" }, _year: { _eq: $year } }
    ) {
      aggregate {
        sum {
          total_won
        }
      }
    }
  }
`;

export const MONTHLY_PAYOUT_GRAPH = gql`
  query getMonthlyPayout($year: String) {
    january: view_month_winning_claim_amounts_aggregate(
      where: { _month: { _eq: "january" }, _year: { _eq: $year } }
    ) {
      aggregate {
        sum {
          total_payout
        }
      }
    }
    february: view_month_winning_claim_amounts_aggregate(
      where: { _month: { _eq: "february" }, _year: { _eq: $year } }
    ) {
      aggregate {
        sum {
          total_payout
        }
      }
    }
    march: view_month_winning_claim_amounts_aggregate(
      where: { _month: { _eq: "march" }, _year: { _eq: $year } }
    ) {
      aggregate {
        sum {
          total_payout
        }
      }
    }
    april: view_month_winning_claim_amounts_aggregate(
      where: { _month: { _eq: "april" }, _year: { _eq: $year } }
    ) {
      aggregate {
        sum {
          total_payout
        }
      }
    }
    may: view_month_winning_claim_amounts_aggregate(
      where: { _month: { _eq: "may" }, _year: { _eq: $year } }
    ) {
      aggregate {
        sum {
          total_payout
        }
      }
    }
    june: view_month_winning_claim_amounts_aggregate(
      where: { _month: { _eq: "june" }, _year: { _eq: $year } }
    ) {
      aggregate {
        sum {
          total_payout
        }
      }
    }
    july: view_month_winning_claim_amounts_aggregate(
      where: { _month: { _eq: "july" }, _year: { _eq: $year } }
    ) {
      aggregate {
        sum {
          total_payout
        }
      }
    }
    august: view_month_winning_claim_amounts_aggregate(
      where: { _month: { _eq: "august" }, _year: { _eq: $year } }
    ) {
      aggregate {
        sum {
          total_payout
        }
      }
    }
    september: view_month_winning_claim_amounts_aggregate(
      where: { _month: { _eq: "september" }, _year: { _eq: $year } }
    ) {
      aggregate {
        sum {
          total_payout
        }
      }
    }
    october: view_month_winning_claim_amounts_aggregate(
      where: { _month: { _eq: "october" }, _year: { _eq: $year } }
    ) {
      aggregate {
        sum {
          total_payout
        }
      }
    }
    november: view_month_winning_claim_amounts_aggregate(
      where: { _month: { _eq: "november" }, _year: { _eq: $year } }
    ) {
      aggregate {
        sum {
          total_payout
        }
      }
    }
    december: view_month_winning_claim_amounts_aggregate(
      where: { _month: { _eq: "december" }, _year: { _eq: $year } }
    ) {
      aggregate {
        sum {
          total_payout
        }
      }
    }
  }
`;

export const GET_ALL_CUSTOMERS = gql`
  query getAllCustomers {
    customers: customer(where: { user_type: { _eq: "customer" } }) {
      first_name
      user_type
      device_id
      digital_address
      email
      ghana_card
      id
      init_platform
      last_name
      national_id
      is_active
      middle_name
      created_by
      phone_number
      region_id
      selfie
      status
      town_city
      blocked
      blocked_reason
      address
    }
  }
`;

export const GET_MAILING_LIST = gql`
  query getMailingList {
    mailingList: customer {
      first_name
      full_name
      email
      id
    }
  }
`;

export const GET_MOST_STAKED_NUMBERS = gql`
  query most_freq_stakes($game_id: uuid) {
    view_most_freq_stakes(where: { game_id: { _eq: $game_id } }) {
      staked_line
      stake_type
      freq
      total_stake_amount
      possible_payout
    }
  }
`;

export const GET_ALL_REGIONS = gql`
  query GetAllRegions {
    regions: region {
      name
      id
    }
  }
`;

export const GET_ALL_SENT_SMS = gql`
  query getSentSmS {
    notification(where: { channels: { _eq: ["sms"] } }) {
      id
      data
      html
      target
      channels
      recipients {
        customer_id
        customer {
          full_name
          phone_number
        }
        sms_delivered
        sms_delivered_at
      }
      title
      created_at
      processed_at
      message
    }
  }
`;

export const GET_ALL_PAYOUT_STATUS = gql`
  query MyQuery {
    winning_claim(
      where: { status: { _in: ["processing", "failed"] } }
      order_by: { created_at: asc }
    ) {
      id
      amount
      reference
      third_party_ref
      account_number
      payment_provider
      customer {
        full_name
        phone_number
        id
      }
      created_at
      status
    }
  }
`;

export const GET_ALL_IN_APP_NOTIFICATIONS = gql`
  query getSentInAppNotifications {
    notification(where: { channels: { _eq: ["app"] } }) {
      id
      data
      html
      target
      channels
      recipients {
        customer_id
        customer {
          full_name
          phone_number
        }
        sms_delivered
        sms_delivered_at
      }
      title
      created_at
      processed_at
      message
    }
  }
`;

export const GET_ALL_SENT_EMAIL = gql`
  query getSentEmail {
    notification(where: { channels: { _eq: ["email"] } }) {
      id
      data
      html
      target
      channels
      recipients {
        customer_id
        customer {
          full_name
          phone_number
        }
        sms_delivered
        sms_delivered_at
      }
      title
      created_at
      processed_at
      message
    }
  }
`;

export const SUBSCRIPTION_OF_GAMES = gql`
  subscription GetAllEventsOfProduct($productID: uuid!) {
    game(where: { product_id: { _eq: $productID } }) {
      claim_expiry_hours
      closed_at
      created_by
      event_code
      game_of_day
      id
      is_active
      product_id
      run_end
      run_start
      results
      schedule_end
      schedule_start
      product {
        description
        max_stake_amount
        min_stake_amount
        name
        slogan
        schedule_day
      }
    }
  }
`;

export const GET_GAMES = gql`
  query getGames {
    games: product {
      name
      id
    }
  }
`;

export const GET_SIGNUP_USERS = gql`
  query getSignupReport($_startDate: timestamptz, $_endDate: timestamptz) {
    customer_aggregate(
      where: { created_at: { _gte: $_startDate, _lte: $_endDate } }
    ) {
      aggregate {
        count
      }
      nodes {
        first_name
        last_name
        email
        phone_number
        status
        region {
          id
          name
        }
      }
    }
  }
`;

export const GET_STAKES_REPORT = gql`
  query getTotalStakesReport(
    $_startDate: timestamptz
    $_endDate: timestamptz
    $_in: [uuid!]
  ) {
    stake_aggregate(
      where: {
        game: { product_id: { _in: $_in } }
        created_at: { _gte: $_startDate, _lte: $_endDate }
        is_paid: { _eq: true }
      }
    ) {
      aggregate {
        count
        sum {
          staked_amount
        }
      }
    }
    stakes: stake(
      where: {
        game: { product_id: { _in: $_in } }
        created_at: { _gte: $_startDate, _lte: $_endDate }
      }
    ) {
      customer {
        full_name
      }
      staked_amount
      staked_by
      winning_amount
      created_at
      stake_type
    }
  }
`;

export const GET_GAME_REPORT = gql`
  query getGameReport(
    $_startDate: date
    $_endDate: date
    $_prodID: uuid
    $_in: [uuid!]
  ) @cached {
    game(
      order_by: { schedule_end: desc }
      where: {
        schedule_start: { _gte: $_startDate }
        schedule_end: { _lte: $_endDate }
        product_id: { _in: $_in }
      }
    ) {
      product {
        name
      }
      schedule_start
      schedule_end
      event_code
      odds
      results
      stakes(order_by: { created_at: desc }) {
        agent {
          full_name
          phone_number
          email
        }
        customer {
          full_name
          phone_number
          email
        }
        agent_commission_rate
        agent_commission_amount
        amount_per_stake
        num_stakes
        paid_amount
        is_paid
        is_processed
        losing_lines
        losing_picks
        is_claimed
        picked_lines
        picks
        stake_status
        staked_amount
        staked_by
        winning_amount
        winning_balance
        winning_lines
        winning_picks
        stake_type
        num_stakes_won
        payment {
          agent_id
        }
        payment_reference
      }
    }
  }
`;

export const GET_CUSTOMER_PAYOUT = gql`
  query GetCustomerPayout(
    $_startDate: timestamptz
    $_endDate: timestamptz
    $_customerType: String
  ) {
    winning_claim(
      where: {
        status: { _eq: "paid" }
        created_at: { _gte: $_startDate, _lte: $_endDate }
        customer: { user_type: { _eq: $_customerType } }
      }
      order_by: { created_at: desc }
    ) {
      account_number
      amount
      channel
      customer_id
      payment_provider
      reference
      status
      stakes {
        amount_per_stake
        num_stakes
        paid_amount
        picks
        stake_type
        staked_amount
        created_at
        num_stakes_won
        reference
        status
        stakes {
          amount_per_stake
          num_stakes
          paid_amount
          picks
          stake_type
          staked_amount
          created_at
          num_stakes_won
          reference
          stake_status
          game {
            event_code
            product {
              name
            }
          }
          staked_by
          agent {
            full_name
            address
          }
        }
        customer {
          digital_address
          email
          full_name
          phone_number
          region_id
          town_city
          agent_commission_payments {
            amount
            account_number
          }
          address
        }
        claim_at
      }
      customer {
        digital_address
        email
        full_name
        phone_number
        region_id
        town_city
        agent_commission_payments {
          amount
          account_number
        }
        address
      }
      id
      created_at
    }
  }
`;

export const GET_ALL_WINNERS = gql`
  query MyQuery {
    winning_claim(where: { status: { _eq: "pending" } }) {
      account_number
      amount
      channel
      created_at
      customer {
        address
        full_name
        id
        region {
          name
        }
        email
        phone_number
      }
      status
      stakes {
        game {
          closed_at
          event_code
          product {
            name
            id
          }
        }
        num_stakes_won
        picked_lines
        stake_type
        stake_status
        staked_amount
        staked_by
        winning_amount
        winning_lines
      }
    }
  }
`;

export const GET_ALL_PRODUCTS_NAME = gql`
  query MyQuery {
    product {
      id
      name
    }
  }
`;

export const GET_ALL_CUSTOMER_WINNERS_SUB = gql`
  subscription getGameWinners(
    $start_date: date
    $end_date: date
    $product_id: uuid!
  ) {
    games: game(
      where: {
        product_id: { _eq: $product_id }
        _and: [
          { schedule_end: { _gte: $start_date } }
          { schedule_end: { _lte: $end_date } }
        ]
      }
    ) {
      id
      event_code
      closed_at
      product {
        id
        name
      }
      payable_winners(where: { claim_status: { _eq: "pending" } }) {
        customer {
          id
          full_name
          phone_number
        }
        claim_status
        total_stake_amount
        total_winning_amount
      }
    }
  }
`;

export const GET_ALL_CUSTOMER_WINNERS = gql`
  query getGameWinners($start_date: date, $end_date: date, $product_id: uuid!) {
    games: game(
      where: {
        product_id: { _eq: $product_id }
        _and: [
          { schedule_end: { _gte: $start_date } }
          { schedule_end: { _lte: $end_date } }
        ]
      }
    ) {
      id
      event_code
      closed_at
      product {
        id
        name
      }
      payable_winners(where: { claim_status: { _eq: "pending" } }) {
        customer {
          id
          full_name
          phone_number
        }
        claim_status
        total_stake_amount
        total_winning_amount
      }
    }
  }
`;

export const FILTER_MOST_STAKED_NUMBERS = gql`
  query most_freq_stakes_with_filter($game_id: uuid, $stake_type: String) {
    view_most_freq_stakes(
      where: { game_id: { _eq: $game_id }, stake_type: { _eq: $stake_type } }
    ) {
      staked_line
      stake_type
      freq
      total_stake_amount
      possible_payout
    }
  }
`;

export const GET_ALL_BANKS = gql`
  query GetAllbanks {
    bank {
      id
      name
    }
  }
`;

export const GET_ALL_AGENT_FUNDS_OWED = gql`
  query getAgentPayout($region_id: uuid!) {
    customer(
      where: { user_type: { _eq: "agent" }, region_id: { _eq: $region_id } }
    ) {
      full_name
      region {
        name
        id
      }
      phone_number
      customer_account {
        balance
        credits
        debits
      }
      id
    }
  }
`;

export const PAYABLE_GAME_AGENT = gql`
  query PayableGameAgents(
    $product_id: uuid
    $start_date: date
    $end_date: date
  ) {
    game(
      order_by: { schedule_end: desc }
      where: {
        payments: {
          agent_id: { _is_null: false }
          commission_payment_status: { _nin: ["paid", "processing"] }
        }
        game_agents: { id: { _is_null: false } }
        stakes: { is_paid: { _eq: true } }
        product_id: { _eq: $product_id }
        _and: [
          { schedule_end: { _lte: $end_date } }
          { schedule_end: { _gte: $start_date } }
        ]
      }
    ) {
      id
      schedule_start
      schedule_end
      closed_at
      event_code
      product {
        name
        image
      }
      game_agents(
        where: {
          payments: {
            status: { _eq: "paid" }
            commission_payment_status: { _nin: ["paid", "processing"] }
          }
        }
      ) {
        id
        agent {
          full_name
          stakes_aggregate(
            where: {
              game: {
                _and: [
                  { schedule_end: { _lte: $end_date } }
                  { schedule_end: { _gte: $start_date } }
                ]
                product_id: { _eq: $product_id }
              }
              is_paid: { _eq: true }
            }
          ) {
            aggregate {
              total_number_of_stakes: count(columns: [agent_id])
              sum {
                total_staked_amount: staked_amount
                total_commissions: agent_commission_amount
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ADMIN_ROLES = gql`
  query getAdminRoles {
    admin_roles {
      code
      id
      name
      is_active
    }
  }
`;

export const GET_AGENT_BALANCE_DETAILS = gql`
  subscription GetRegionalBalanceDetails($region_id: uuid!) {
    customer_account(
      where: {
        customer: {
          region_id: { _eq: $region_id }
          is_deleted: { _eq: false }
          user_type: { _eq: "agent" }
        }
        balance: { _gt: "0" }
      }
    ) {
      id
      customer {
        first_name
        full_name
        user_type
        address
        phone_number
        status
        town_city
      }
      balance
    }
  }
`;

export const GET_AGENTS_TO_BE_PAID = gql`
  query GetAgentsToBepaid($product_id: uuid!) {
    game(
      order_by: { closed_at: desc }
      where: {
        closed_at: { _is_null: false }
        payable_game_agents: {
          commission_status: { _eq: "pending" }
          total_commission_balance: { _gt: 0 }
        }
        product_id: { _eq: $product_id }
      }
    ) {
      id
      event_code
      closed_at
      product {
        id
        name
      }
      payable_game_agents(
        where: {
          agent: { is_deleted: { _eq: false } }
          commission_status: { _eq: "pending" }
        }
      ) {
        agent {
          first_name
          id
          full_name
          phone_number
          customer_account {
            momo_provider
          }
          region {
            id
            name
          }
          status
          town_city
          user_type
        }
        commission_status
        schedule_end
        total_commission
        total_commission_balance
        total_stake_amount
        total_winning_amount
      }
    }
  }
`;

export const GETALLWINNINGS = gql`
  query AllGetAllWinnings {
    view_all_winnings {
      event_code
      full_name
      phone_number
      winning_amount
      created_at
      game_name
      stake_type
    }
  }
`;

export const AGENTCOMMISSIONSUMMARY = gql`
  query getAgentCommisionSummary {
    view_agent_unpaid_commissions {
      amt_per_stake_average
      commission_amt_20
      commission_amt_20_fmt
      commission_amt_35_fmt
      full_name
      num_stakes_total
      paid_amt_total
      phone_number
    }
  }
`;

export const GET_ALL_WRITERS = gql`
  query GetAllWriters {
    writer {
      first_name
      last_name
      email
      writer_fm_id
      created_at
      auth_user_id
      address
      digital_address
      id
      ghana_card
      middle_name
      national_id
      phone_number
      region_id
      town_city
      selfie
      is_active
      blocked
      blocked_reason
    }
  }
`;

export const GET_UNASSIGNED_WRITERS = gql`
  query getUnassignedWriters {
    writers: view_writers_not_in_writer_fm {
      email
      first_name
      last_name
      phone_number
      region_id
      id
      status
    }
  }
`;

export const GET_ALL_WRITERS_OF_WRITERFM = gql`
  query getWritersofWriterFM($writer_fm: uuid) {
    writers: view_writers_of_writerfm(
      where: { writerfm_id: { _eq: $writer_fm } }
    ) {
      email
      fullname
      id
      phone_number
      status
    }
  }
`;

export const GET_ALL_WRITERFMS = gql`
  query getAllWriterFms {
    writer_fms(
      where: { status: { _eq: "active" }, is_deleted: { _eq: false } }
    ) {
      address
      id
      ghana_card
      first_name
      email
      phone_number
      last_name
      region {
        id
        name
      }
      status
    }
  }
`;

export const GET_WRITER_STAKES = gql`
  query getWriterQuery($writer_id: uuid!) {
    stake(where: { writer_id: { _eq: $writer_id } }) {
      amount_per_stake
      customer {
        full_name
        phone_number
      }
      game_id
      stake_status
      stake_type
      staked_amount
      winning_amount
      winning_picks
      reference
      winning_balance
      paid_amount
      num_stakes
      is_paid
      game {
        product {
          name
        }
      }
    }
  }
`;

export const GETWRITERSTAKES = gql`
  query GetWriterStakes($writer_id: uuid!) {
    stake(
      order_by: { created_at: desc }
      where: { writer_id: { _eq: $writer_id } }
    ) {
      id
      amount_per_stake
      created_at
      draw_type
      num_stakes
      num_stakes_won
      stake_type
      staked_amount
      winning_amount
      stake_status
      game {
        event_code
        id
        product {
          name
        }
      }
      paid_amount
    }
  }
`;

export const GETWRITERS = gql`
  query GetWriters {
    writer(order_by: { first_name: asc }) {
      id
      first_name
      last_name
    }
  }
`;
