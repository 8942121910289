import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_ALL_PRODUCTS_SPONZIO } from '../../Graphql/Queries';
import { CircularProgress } from '@material-ui/core';
import ProductCard from './ProductCard';
import { Link } from 'react-router-dom';
// import { useHistory } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

const Setups = () => {
  const { data: productData, loading: productsLoading } = useQuery(
    GET_ALL_PRODUCTS_SPONZIO,
  );
  const data = [];

  if (productData) {
    data.push({
      day: 'Sunday',
      games: productData.sunday,
    });
    data.push({
      day: 'Monday',
      games: productData.mondays,
    });
    data.push({
      day: 'Tuesday',
      games: productData.tuesday,
    });
    data.push({
      day: 'Wednesday',
      games: productData.wednesday,
    });
    data.push({
      day: 'Thursday',
      games: productData.thursday,
    });
    data.push({
      day: 'Friday',
      games: productData.friday,
    });
    data.push({
      day: 'Saturday',
      games: productData.saturday,
    });
  }

  // const history = useHistory();
  const navigate = useNavigate();

  return (
    <div style={{ padding: 20 }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 30,
        }}
      >
        {/* <h1></h1> */}

        <button
          className="btn btn-info"
          onClick={() => navigate('/admin/create-setup')}
        >
          <img alt="" style={{ position: 'relative', top: -1 }} /> Add Product
        </button>
      </div>

      {productsLoading ? (
        <CircularProgress />
      ) : (
      // <div style={{ padding: 10, width: "100%" }}>
      //   <Tabs
      //     defaultActiveKey="home"
      //     id="uncontrolled-tab-example"
      //     className="mb-3"
      //   >
      //     <Tab eventKey="home" title="Monday">
      //       {data?.length > 0 ? (
      //         <>

      //         </>
      //       ) : (
      //         <div
      //           style={{
      //             display: "flex",
      //             justifyContent: "center",
      //             alignItems: "center",
      //             height: "70vh",
      //             flexDirection: "column",
      //           }}
      //         >
      //           <img
      //             style={{
      //               height: 150,
      //               width: 180,
      //               marginBottom: 25,
      //             }}
      //             src={No_Application}
      //           />

      //           <h3 style={{ color: "#0E3E86" }}>No Applications</h3>
      //         </div>
      //       )}
      //     </Tab>

        //   </Tabs>
        // </div>
        <div
          style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}
        >
          {data &&
            data.map((product, key) => {
              return (
                <div key={key} style={{ padding: 5, textAlign: 'center' }}>
                  <h2>{product.day}</h2>
                  <hr />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                    }}
                  >
                    {product.games.map((game, key) => {
                      return (
                        <Link
                          key={key}
                          to={{
                            pathname: '/admin/create-setup',
                            state: game,
                            data: {
                              actionType: 'edit',
                            },
                          }}
                          style={{ textDecoration: 'none' }}
                        >
                          <div style={{ margin: 15, textAlign: 'left' }}>
                            <ProductCard
                              productName={game?.name}
                              gameDay={game?.schedule_day[0]}
                              productType={game?.product_type.category}
                              startDay={game?.schedule_start}
                              endDay={game?.schedule_end}
                              productImage={game?.image}
                            />
                          </div>
                        </Link>
                      );
                    })}
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default Setups;
