// import DashboardView from "./views/DashboardView";
// import InsuranceProductView from "./views/InsuranceProduct";
import AdminView from "./views/AdminView";
import SomethingWentWrong from "./auth/404";
import NotificationView from "./views/NotificationView";
// import TicketView from "./views/TicketView";
import SmsView from "./views/SmsView/Sms/Index";
import ScheduleSMSView from "./views/SmsView/Sms/ScheduledSms";
import SentSMSView from "./views/SmsView/Sms/Sent";
import SentEmail from "./views/SentEmail/Sent";
// import RequestView from "./views/RequestView/Index";
import NewEmail from "./views/EmailView/index";
// import UploadadeFiles from "./views/UploadedViews/index";
// import ImagePreview from "./views/UploadedViews/ImagPreview";
// import QuoteView from "./views/QuoteView/Index";
import Setups from "./views/SetupView/Setups";
import CreateProduct from "./views/SetupView/CrreateProduct";
import ProductDetails from "./views/SetupView/ProductDetails";
import Index from "./views/ReportView/index";
import PayoutView from "./views/PayoutView";
import EventList from "./views/EventView";
import Games from "./views/GamesView";
import ReportOverview from "./views/ReportsView/ReportOverview";
// import SetupsV2 from './views/SetupView';
import AgentView from "./views/Agent";
import AgentDetails from "./views/Agent/AgentDetails";
import PricingView from "./views/Pricing";
import AdminsView from "./views/Admin";
import AdminsDetails from "./views/Admin/AdminDetails";
import UserManagement from "./views/Users";
import CustomerIndex from "./views/Users/CustomerIndex";
import CustomerDetails from "./views/Users/CustomerDetails";
import AgentBalanceIndex from "./views/PayoutView/AgentBalance";
import AgentPayoutIndex from "./views/PayoutView/AgentPayout";
// import InAppNotifications from './views/InAppNotifications/InAppNotifications';
// import SentNotifications from './views/InAppNotifications/SentNotifications';
import RegionalBalance from "./views/PayoutView/RegionalBalance";
import PayoutResolver from "./views/PayoutView/PaoyoutResolver";
import BalanceDetails from "./views/PayoutView/BalanceDetails";
import WinnersReport from "./views/ReportView/Winners";
import AgentCommissionIndex from "./views/PayoutView/AgentCommisions";
import WriterView from "./views/Writers";
import WriterFmView from "./views/WriterFmView";
import WriterDetails from "./views/Writers/WriterDetails";
import WriterFmWriterView from "./views/WriterView";
import WriterAnalytic from "./views/WriterView/WriterAnalytic";
// import SettingsView from "./views/SettingsView/index";
import WriterFmReport from "./views/WriterView/report";

export const PrivateRoutes = [
  // {
  //   path: "/dashboard",
  //   name: "dashboard",
  //   exact: true,
  //   component: DashboardView,
  //   layout: "/admin",
  // },
  { path: "/payout", name: "payouts", component: PayoutView, layout: "/admin" },
  // {
  //   path: "/ticket",
  //   name: "intermediaries",
  //   component: TicketView,
  //   layout: "/admin",
  // },
  {
    path: "/notification",
    name: "notification",
    component: NotificationView,
    layout: "/admin",
  },
  // {
  //   path: "/product",
  //   name: "product",
  //   component: InsuranceProductView,
  //   layout: "/admin",
  // },
  {
    path: "/manageadmin",
    name: "manageadmin",
    component: AdminView,
    layout: "/admin",
  },
  {
    path: "/not-found",
    name: "not-found",
    component: SomethingWentWrong,
    layout: "/admin",
  },
  {
    path: "/sms/new-sms",
    name: "new-sms",
    component: SmsView,
    layout: "/admin",
  },
  {
    path: "/scheduled-sms",
    name: "scheduled-sms",
    component: ScheduleSMSView,
    layout: "/admin",
  },
  {
    path: "/sent-sms",
    name: "scheduled-sms",
    component: SentSMSView,
    layout: "/admin",
  },
  {
    path: "/sent-email",
    name: "sent-email",
    component: SentEmail,
    layout: "/admin",
  },
  {
    path: "/email-notification",
    name: "scheduled-sms",
    component: NewEmail,
    layout: "/admin",
  },
  { path: "/setup", name: "setups-view", component: Setups, layout: "/admin" },
  {
    path: "/create-setup",
    name: "setups-view",
    component: CreateProduct,
    layout: "/admin",
  },
  {
    path: "/product-details",
    name: "product-detail-view",
    component: ProductDetails,
    layout: "/admin",
  },
  { path: "/report", name: "report-view", component: Index, layout: "/admin" },
  { path: "/payout", name: "payouts", component: PayoutView, layout: "/admin" },
  {
    path: "/product-games",
    name: "payouts",
    component: EventList,
    layout: "/admin",
  },
  {
    path: "/writer-fm-report",
    name: "payouts",
    component: WriterFmReport,
    layout: "/admin",
  },
  { path: "/eventlist", name: "payouts", component: Games, layout: "/admin" },
  {
    path: "/report-and-analytics",
    name: "report-and-analytics",
    component: ReportOverview,
    layout: "/admin",
  },
  {
    path: "/Agents",
    name: "AgentView",
    component: AgentView,
    layout: "/admin",
  },
  {
    path: "/Writers",
    name: "AgentView",
    component: WriterView,
    layout: "/admin",
  },
  {
    path: "/writerFms",
    name: "AgentView",
    component: WriterFmView,
    layout: "/admin",
  },
  // {
  //   path: "/writerstakes",
  //   name: "AgentView",
  //   component: WriterStakes,
  //   layout: "/admin",
  // },
  {
    path: "/mywriters",
    name: "AgentView",
    component: WriterFmWriterView,
    layout: "/admin",
  },
  {
    path: "/agent-details",
    name: "AgentDetailsView",
    component: AgentDetails,
    layout: "/admin",
  },
  {
    path: "/writer-details",
    name: "WriterDetailsView",
    component: WriterDetails,
    layout: "/admin",
  },
  {
    path: "/pricing",
    name: "pricing",
    component: PricingView,
    layout: "/admin",
  },
  { path: "/admins", name: "admins", component: AdminsView, layout: "/admin" },
  {
    path: "/admins-view",
    name: "admins-view",
    component: AdminsDetails,
    layout: "/admin",
  },
  {
    path: "/user-management",
    name: "user-management",
    component: UserManagement,
    layout: "/admin",
  },
  {
    path: "/customers",
    name: "customers",
    component: CustomerIndex,
    layout: "/admin",
  },
  {
    path: "/customer-details",
    name: "customer-details",
    component: CustomerDetails,
    layout: "/admin",
  },
  {
    path: "/agent-balance",
    name: "agent-payout",
    component: AgentBalanceIndex,
    layout: "/admin",
  },
  {
    path: "/resolve-issues",
    name: "agent-payout-region",
    component: PayoutResolver,
    layout: "/admin",
  },
  {
    path: "/regional-balance",
    name: "agent-payout-region",
    component: RegionalBalance,
    layout: "/admin",
  },
  {
    path: "/agent-payout",
    name: "agent-payout",
    component: AgentPayoutIndex,
    layout: "/admin",
  },
  // {
  // 	path: '/inApp-notifications',
  // 	name: 'inApp-notifications',
  // 	component: InAppNotifications,
  // 	layout: '/admin',
  // },
  // {
  // 	path: '/sent-inApp-notifications',
  // 	name: 'sent-inApp-notifications',
  // 	component: SentNotifications,
  // 	layout: '/admin',
  // },
  {
    path: "/agent-balance-details",
    name: "agent-payoutdetails",
    component: BalanceDetails,
    layout: "/admin",
  },
  {
    path: "/winners-report",
    name: "agent-winners-report",
    component: WinnersReport,
    layout: "/admin",
  },
  {
    path: "/writer-analytic",
    name: "agent-winners-report",
    component: WriterAnalytic,
    layout: "/admin",
  },
  {
    path: "/agent-commission-summary",
    name: "agent-commission-summary",
    component: AgentCommissionIndex,
    layout: "/admin",
  },
];

export default PrivateRoutes;
