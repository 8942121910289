import React, { useState } from "react";
import { GET_ALL_WRITERS } from "../../Graphql/Queries";
import { Tabs, Tab } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
// import { useHistory } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";

const WriterView = () => {
  // const [agents, setAgents] = useState([]);

  const [pendingAgents, setPendingAgents] = useState([]);
  const [approvedAgentes, setApprovedAgents] = useState([]);
  // const history = useHistory();
  const navigate = useNavigate();
  const { loading: agentDataLoading } = useQuery(GET_ALL_WRITERS, {
    onCompleted: (data) => {
      let pending = [];
      let approved = [];

      // console.log(data)

      for (let i = 0; i < data.writer?.length; i++) {
        if (data.writer[i].status !== "active") {
          pending.push(data.writer[i]);
        } else {
          approved.push(data.writer[i]);
        }
      }

      setApprovedAgents(approved);
      setPendingAgents(pending);
    },
  });

  const handleRowClickPending = (rowIndex, dataIndex) => {
    console.log(rowIndex);
    navigate("/admin/writer-details", {
      state: pendingAgents[dataIndex.dataIndex],
    });
  };

  const handleRowClickApproved = (rowIndex, dataIndex) => {
    // console.log(rowIndex);
    navigate("/admin/writer-details", {
      state: approvedAgentes[dataIndex.dataIndex],
    });
  };

  const optionsPending = {
    filterType: "checkbox",
    filter: false,
    print: false,
    selectableRows: false,
    responsive: "scroll",

    // download: false,
    // viewColumns: false,
    // onCellClick: handleCell,
    onRowClick: handleRowClickPending,
    customToolbarSelect: () => {},
    sortOrder: {
      name: "created_at",
      direction: "desc",
    },
  };

  const optionsApproved = {
    filterType: "checkbox",
    filter: false,
    print: false,
    download: false,
    responsive: "scroll",

    viewColumns: false,
    onRowClick: handleRowClickApproved,
    customToolbarSelect: () => {},
    sortOrder: {
      name: "created_at",
      direction: "desc",
    },
  };

  const columns = [
    {
      name: "first_name",
      label: "First Name",
      options: {
        customBodyRender: (value) => (
          <div
            style={{
              position: "relative",
              left: 40,
            }}
          >
            {value}
          </div>
        ),
      },
    },
    {
      name: "last_name",
      label: "Last Name",
      options: {
        customBodyRender: (value) => (
          <div
            style={{
              position: "relative",
              left: 40,
            }}
          >
            {value}
          </div>
        ),
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,

        customBodyRender: (value) => (
          <div
            style={{
              position: "relative",
              left: 10,
            }}
          >
            {value}
          </div>
        ),
      },
    },
    {
      name: "phone_number",
      label: "Phone Number",
      options: {
        filter: true,
        sort: true,

        customBodyRender: (value) => (
          <div
            style={{
              position: "relative",
              left: 30,
            }}
          >
            {value}
          </div>
        ),
      },
    },
    {
      name: "is_active",
      label: "Active Status",
      options: {
        filter: true,
        sort: true,

        customBodyRender: (value) => (
          <>
            {value == true ? (
              <div
                style={{
                  position: "relative",
                  left: 20,
                }}
              >
                Active
              </div>
            ) : (
              <div
                style={{
                  position: "relative",
                  left: 20,
                }}
              >
                Inactive
              </div>
            )}
          </>
        ),
      },
    },
    {
      name: "blocked",
      label: "Blocked Status",
      options: {
        filter: true,
        sort: true,

        customBodyRender: (value) => (
          <>
            {value == true ? (
              <div
                style={{
                  position: "relative",
                  left: 20,
                }}
              >
                Yes
              </div>
            ) : (
              <div
                style={{
                  position: "relative",
                  left: 20,
                }}
              >
                No
              </div>
            )}
          </>
        ),
      },
    },
    // {
    //   name: 'created_at',
    //   label: 'Created at',
    //   option: {
    //     filter: true,
    //     sort: true,
    //     customBodyRender: (val) => {
    //       console.log("helllo")
    //       return <p style={{ position: "relative", left: 0}}>{"hello"}</p>;

    //     },
    //   }
    // },
    {
      name: "blocked_reason",
      label: "Blocked Status",
      option: {
        filter: true,
        sort: true,
        customBodyRender: (val) => {
           return val;
        },
      },
    },
  ];

  return (
    <>
      <div>
        <Tabs
          defaultActiveKey="pending"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="pending" title="Pending Writers">
            <div
              style={{
                padding: 15,
              }}
            >
              <div
                style={{
                  padding: 15,
                }}
              >
                <BiArrowBack
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}
                  size={50}
                />
              </div>

              {agentDataLoading ? (
                <CircularProgress
                  style={{
                    position: "relative",
                    marginTop: "25%",
                    left: "55%",
                  }}
                />
              ) : pendingAgents?.length == 0 ? (
                <div
                  style={{ width: "100vw", textAlign: "center", margin: 50 }}
                >
                  <h1>There are no pending writers</h1>
                </div>
              ) : (
                <MUIDataTable
                  title={"All Pending Writers"}
                  data={pendingAgents ? pendingAgents : []}
                  columns={columns}
                  options={optionsPending}
                />
              )}
            </div>
          </Tab>
          <Tab eventKey="approved" title="Approved Writers">
            <div
              style={{
                padding: 15,
              }}
            >
              <div
                style={{
                  padding: 15,
                }}
                className="d-flex justify-content-between"
              >
                <BiArrowBack
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}
                  size={50}
                />
              </div>

              {agentDataLoading ? (
                <CircularProgress
                  style={{
                    position: "relative",
                    marginTop: "25%",
                    left: "55%",
                  }}
                />
              ) : (
                <MUIDataTable
                  title={"All Approved Writers"}
                  data={approvedAgentes ? approvedAgentes : []}
                  columns={columns}
                  options={optionsApproved}
                />
              )}
            </div>
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default WriterView;
